import React from 'react';
import './Breadcrumb.scss';

type BreadcrumbItem = {
    label: string;
    url?: string;
  };
  
  type BreadcrumbProps = {
    items: BreadcrumbItem[];
  };

  const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav className="breadcrumb-container">
      <ul>
        {items.map((item, index) => (
          <li key={index} className="item">
            {item.url ? (
              <a href={item.url} className="link">
                {item.label}
              </a>
            ) : (
              <span>{item.label}</span>
            )}
            {index < items.length - 1 && <span className="separator"> {'>'} </span>}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;