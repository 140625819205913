import { useState, useRef, useEffect, FC } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PhotoCarousel.scss";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { trackCarouselClick } from "../../helper/utils";

interface ImageData {
  image: string;
  label: string;
  name?: string;
  order: string;
  song: string;
  title: string;  
  label_1?: string;
  label_2?: string;
  description_1?: string;
  description_2?: string;
}

interface PhotoCarouselProps {
  talentImages: ImageData[];
}

const PhotoCarousel: FC<PhotoCarouselProps> = ({ talentImages }) => {
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [infiniteState, setInfiniteState] = useState(false);
  const [centerState, setCenterState] = useState(false);
  const [imageCount, setImageCount] = useState(talentImages.length || 0);

  const slider1 = useRef<Slider | null>(null);
  const slider2 = useRef<Slider | null>(null);

  useEffect(() => {
    setNav1(slider1.current ?? undefined);
    setNav2(slider2.current ?? undefined);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!slider1.current) return;

      if (event.key === "ArrowRight") {
        slider1.current.slickNext();
      } else if (event.key === "ArrowLeft") {
        slider1.current.slickPrev();
      }
    };

    if (slider1.current) {
      slider1?.current?.innerSlider?.list?.setAttribute("tabindex", "-1");
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  const images = talentImages?.length ? talentImages : [];

  const slideHighlighter = (newIndex: number) => {

    if ((imageCount > 5) && (newIndex === 2)) {
      setCenterState(() => true);
      setInfiniteState(() =>true);
    }
    if ((imageCount > 5) && (newIndex === (imageCount -1))) {
      setCenterState(() => false);
      setInfiniteState(() =>false);
    }
    if (newIndex === 0) {
      setCenterState(() => false);
      setInfiniteState(() => false);
    }

  }

  const handleSlideChange = (newIndex: number) => {

    if (newIndex === currentSlide) return;

    const direction = newIndex > currentSlide ? "Left" : "Right";
    slideHighlighter(newIndex);
    requestAnimationFrame(() => {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.classList.contains("slick-arrow")) {
        trackCarouselClick(
          `click_carousel:${direction}`,
          window.location.href,
          "carousel",
          "carousel"
        );
      }
    });
    setCurrentSlide(newIndex);

  };

  const handleThumbnailClick = (index: number) => {

    if (currentSlide === index) return;
    slideHighlighter(index);

    const linkPosition = index.toString();
    trackCarouselClick('thumbnail_click', window.location.href, 'cta', 'thumbnail_block', linkPosition, 'click_content');
    setCurrentSlide(index);
    if (slider1.current) slider1.current.slickGoTo(index);
  };

  const mainSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images.length > 1,
    fade: true,
    infinite: false,
    speed: 100,
    waitForAnimate:false,
    accessibility: true,
    asNavFor: nav2,
    nextArrow: <NextArrow className="next" />,
    prevArrow: <PrevArrow className="prev" />,
    className: "custom-main-slider",
    beforeChange: (_oldIndex: number, newIndex: number) => handleSlideChange(newIndex),
  };

  const thumbSettings = {
    slidesToShow: (window.screen.availWidth === 375 || window.screen.availWidth === 391) ? 2.5 : 5,
    slidesToScroll: 1,
    asNavFor: nav1,
    centerMode: centerState,
    centerPadding: "0",
    speed:100,
    waitForAnimate:false,
    dots: false,
    fade: false,
    infinite: infiniteState,
    swipeToSlide:true,
    mobileFirst: true,
    swipe: true,
    className: "custom-thumbnail-slider",
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2.28,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 412,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 502,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 821,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          infinite: infiniteState,
          centerMode: centerState,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      {/* @ts-ignore: JSX type error */}
      <Slider {...mainSettings} asNavFor={nav2} ref={(slider) => (slider1.current = slider)}>
        {images.map((img, index) => (
          <div key={index}>
            <div className="text-image-container">
              <img
                className="slick-slide-image"
                src={img.image}
                alt={img.title || `Thumbnail ${index}`}
              />
              <div className="text-overlay">
                <p className="img-name">
                  {img?.label_1 && <span className="label">{img?.label_1}</span>} {img?.description_1 && <span className="description">{img?.description_1}</span>}
                </p>
                <p className="img-song">
                {img?.label_2 && <span className="label">{img?.label_2}</span>} {img?.description_2 && <span className="description">{img?.description_2}</span>}
                </p>
              </div>
            </div>
            <div className="slid-image-desc">
              <span className="start-text">
              {img.label}
              </span>
              {(images.length > 1 ) && (<span className="end-text">
                {index + 1}/{images.length}
              </span>)}
            </div>
          </div>
        ))}
      </Slider>
      {/* @ts-ignore: JSX type error */}
      {(images.length > 1 ) && <Slider {...thumbSettings} asNavFor={nav1} ref={(slider) => (slider2.current = slider)}>
        {images.map((img, index) => (
          <div key={index} onClick={() => handleThumbnailClick(index)}>
            <img
              src={img.image} 
              alt={`Thumbnail ${index}`}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        ))}
      </Slider>}
    </div>
  );
};

export default PhotoCarousel;