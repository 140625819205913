import { FC } from "react";
import { ReactComponent as LeftIcon } from "./assets/left-icon.svg";
import { trackCarouselClick } from "../../helper/utils";

interface NextArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NextArrow: FC<NextArrowProps> = ({ className, style, onClick }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    trackCarouselClick("click_carousel:right", window.location.href, "carousel", "carousel");
    onClick?.();
  };
  return (
    <div
      className={className}
      onClick={handleClick}
    >
      <LeftIcon className="svg-nav-icon"/>
    </div>
  );
};

export default NextArrow;