import React, { useEffect, useState } from 'react'
import { GetStoriesList } from '../../../api/Category.service'
import Story from '../chunks/StoryCard'
// import { useMenuContext } from '../../../context/MenuContext'
import { useStoryContext } from '../../../context/StoryContext'
// import { ourYoungPeople, newYorkKnicks, newYorkRangers } from '../../../helper/iconPath'
import './blog.scss'

const Blog = () => {
  const { data, setData } = useStoryContext()
  const [status, setStatus] = useState<{ loading: boolean; error: string | null }>({
    loading: true,
    error: null,
  })

  const fetchData = async () => {
    try {
      const res = await GetStoriesList()
      if (!res?.error) {
        const newData = res?.data?.storyData
        setData(newData)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: "'Error fetching data'" })
      }
    } catch (error) {
      setStatus({ loading: false, error: `An error occurred while fetching data` })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }
  useEffect(() => {
    if (data.length === 0) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    $('.superblockslider__track > p').replaceWith(function () {
      return $(this).contents()
    })
  }, [data])

  if (status.error) {
    return <div>Error: {status.error}</div>
  }

  return (
    <div className='blog-wrapper'>
      <div className='blog-content-wrapper'>
        <div className='blog-content'>
          <div className='blog-content__item'>
            <h1 className='item-title'>All Stories</h1>
            <Story data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
