import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './dropdown.scss'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useMenuContext } from '../../../../context/MenuContext'
import Loader from '../../../../components/Loader/Loader'
import { DONATE_ROUTE } from '../constant'

const Dropdown: React.FC<DropdownProps> = ({
  navIndex,
  setsubActiveNav,
  subActiveNav,
  isMobile,
  setnavMobile,
}) => {
  const parser = new DOMParser()
  const navigate = useNavigate()
  let { menu, loader } = useMenuContext()
  const width = useWindowSize()
  const [path, setpath] = useState('')
  const location = useLocation()
  let nav = menu?.find((item: any) => item?.uid === navIndex)
  const getNavItems = () => {
    if (nav?.category === 'blog') {
      let subnav = nav?.subCategory?.map((item: any) => ({ ...item, tag: item?.title }))
      return subnav?.sort((a: any, b: any) => a?.nav_order - b?.nav_order)
    } else {
      let subnav = nav?.subCategory?.sort((a: any, b: any) => a?.nav_order - b?.nav_order)
      return subnav
    }
  }

  let subNavItems = getNavItems()
  let isForMobile = width <= 1200
  useEffect(() => {
    const parentId=location.pathname.split('/')[1]
    if (parentId) {
      setpath(parentId)
    }    
  }, [location])
  

  if ((subNavItems?.length === 0 || !subNavItems) && !isMobile) {
    // to hide the dropdown
    return null
  }

  if (isForMobile && !isMobile) {
    return null
  }


  const clickHandler = (item: any) => {
    setsubActiveNav(item?.uid)
    setnavMobile(false)

    if (item.uid === 'donate') {
      /**
       * Adding page from the CMS
       */
      window.location.href = DONATE_ROUTE
      return null;
    }
    if (item.uid === "dream-notes") {
      return navigate(`/get-involved/${item.uid}`)
    }
    if (item.uid === 'blog') {
      return navigate(`/${item.uid}`)
    }
    if (item.hasOwnProperty('tag') && item?.tag !== 'Blog') {
      navigate(`/blog/tag/${encodeURIComponent(item.title)}`)
    } else navigate(`${navIndex}/${item?.uid}`)
  }

  if (loader) {
    return <Loader />
  }

  return (
    <span className='nav--dropdown'>
      <li className='nav-item dropdown blue-strip-active-menu' id='blue-strip-active-menu'>
        <ul className={`dropdown-menus show content--navbar`}>
          {subNavItems?.map((item: any, index: any) => {
            return (
              <li key={index} id={item?.title} className='listScroll'>
                <button
                  className={`dropdown-item  ${subActiveNav === item?.uid && 'dropwdown--active'} `}
                  onClick={() => clickHandler(item)}
                >
                  { parser?.parseFromString(item?.title, 'text/html')?.body?.textContent}
                </button>
              </li>
            )
          })}
        </ul>
      </li>
    </span>
  )
}

type DropdownProps = {
  navIndex?: any
  setsubActiveNav: (active: any) => void
  subActiveNav?: any
  isMobile?: boolean
  setnavActive?: (active: any) => void
  setnavMobile: (active: any) => void
}

export default Dropdown
