import Story from '../chunks/StoryCard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Loader from '../../../components/Loader/Loader'
import SeeAllStories from '../../../components/SeeAllStories/SeeAllStories'
import { useParams } from 'react-router-dom'
import '../Blog/blog.scss'
import { getHtml } from '../../../helper/utils'
import NoDataFound from '../../../components/NoDataFound/NoDataFound'
import { getTags } from '../../../api/tags.service'
import PageNotFound from '../../../components/PageNotFound/PageNotFound'
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'

interface story {
  content: string
  // Other properties if needed
}

const TaggedStoryPage = () => {
  const [status, setStatus] = useState<{ loading: boolean; error: string | null }>({
    loading: true,
    error: null,
  })
  const [storyData, setStoryData] = useState<any>([])

  const { tag } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to top when component mounts
  }, [tag])

  const breadcrumbItems = useMemo(() => {
    return [
      { label: 'Garden of Dreams Foundation', url: '/' },
      { label: 'Blog', url: '/blog' },
      { label: `${tag}`, url: '' },
    ]
  
  }, [tag])

  const fetchTaggedStory = useCallback(async () => {
    try {
      const res: any = await getTags(tag)
      if (!res?.error) {
        setStoryData(res?.data?.article)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: 'Error fetching data' })
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.status === 403) {
        const res: any = await getTags(tag, true)
        if (!res?.error) {
          setStoryData(res?.data?.article)
        }
      }
      setStatus({ loading: false, error: 'An error occurred while fetching data' })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }, [tag])

  useEffect(() => {
    fetchTaggedStory()
  }, [tag])

  if (status?.loading) {
    return <Loader />
  }

  if (status.error) {
    return <div>Error: {status.error}</div>
  }

  return (
    <>
      <div>
        <Breadcrumb items={breadcrumbItems} />
      </div>
      {storyData?.content && <div dangerouslySetInnerHTML={getHtml(storyData?.content)} />}
      <div className='blog-wrapper'>
        <div className='blog-content-wrapper'>
          <div className='blog-content'>
            <div className='blog-content__item'>
              <SeeAllStories tags={tag} />
            </div>
            <div className='blog-content__item'>
              <h1 className='item-title'>All Stories</h1>
              {storyData && storyData?.length ? <Story data={storyData} /> : <PageNotFound />  }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TaggedStoryPage