import React from 'react'
import { DONATE_ROUTE, headerBtn } from '../constant';
import './HeaderBtn.scss';
import { useNavigate } from 'react-router-dom'

const HeaderBtn: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className='header-button'>
      {headerBtn.map((item, index) => (
        <button className={`btn ${item.class}`} key={index} onClick={item.onclick}>
          {item.text}
          {item.subDec && <span className="sub-desc">{item.subDec}</span>}
        </button>
      ))}
      <button className='btn btn-blue' onClick={() => (window.location.href = DONATE_ROUTE)}>
          Donate
      </button>
    </div>
  )
}

export default HeaderBtn
