import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getHtml, getMetaData } from '../../../helper/utils'
import { getArticleById, getTalentShowImages } from '../../../api/Category.service'
import { useCategoryContext } from '../../../context/Context'
import Loader from '../../../components/Loader/Loader'
import Donate from '../../Donate/donate'
import Blog from '../../Stories/Blog/Blog'
import NoDataFound from '../../../components/NoDataFound/NoDataFound'
import { landingId } from '../../../helper/constant'
import { Helmet } from 'react-helmet-async'
import { Carousel } from 'bootstrap'
import { getTags } from '../../../api/tags.service'
import ReactDOM from 'react-dom' // Import ReactDOM
import CarouselOwl from '../../../components/Carousel/Owlcarousel'
import HeroBannerSlider from '../../../components/HeroBanneSlider/HeroBannerSlider'
import PageNotFound from '../../../components/PageNotFound/PageNotFound'
import PhotoCarousel from '../../../components/PhotoCarousel/PhotoCarousel';
import { trackCarouselClick } from '../../../helper/utils';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import { useMenuContext } from '../../../context/MenuContext'

const Category: React.FC = () => {
  const [data, setdata] = useState<any>({})
  const { id, sid, pid } = useParams()
  const { category, setcategory } = useCategoryContext()
  const [loader, setloader] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [uid, setUid] = useState('')

  let { menu } = useMenuContext()

  const hanldeCarouselCLick = (item: any) => {
    navigate(`/blog/${item?.uid}`)
  }


  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const findCarousleValue = () => {
      const slider = document.getElementById("talent-show-carousel");
      if (slider) {
          const id = slider.getAttribute('id')
          const carouselId = slider.getAttribute('data-tag')
        
          if (carouselId && id) {
            const getData = async (carouselId: any) => {
              var targetElement = document.getElementById(id)
              const carouselImages: any = await getTalentShowImages(carouselId);
              const images = carouselImages?.data ?? [];
              if (images.length && targetElement) {
                images.sort((a: any, b: any) => Number(a.order) - Number(b.order));
                ReactDOM.render(
                  <div className='photo-carousel'>
                    <PhotoCarousel talentImages={images ?? []}/>
                  </div>
                ,
                targetElement,
                )
              }
            }
            getData(carouselId);
          } else {
            console.log(' Tag attribute not found on the element')
          }
      } else {
        timeoutId = setTimeout(findCarousleValue, 100)
      }
    }

    findCarousleValue()
    return () => {
      clearTimeout(timeoutId)
    }
  }, [id])

  useEffect(() => {
    const embedId = 'flockler-embed-194b8084a410ff00a3881872ae19ca8f'
    const checkAndLoadScript = () => {
      const embedElement = document.getElementById(embedId)
      if (embedElement) {
        const script = document.createElement('script')
        script.src = 'https://plugins.flockler.com/embed/18f16ce16e10cf3f2c1f31cf797dfeb0/194b8084a410ff00a3881872ae19ca8f'
        script.async = true

        document.head.appendChild(script)
      } else {
        setTimeout(checkAndLoadScript, 100)
      }
    }
    checkAndLoadScript()
  }, [id])

  const isBlogPage = location.pathname.split('/').includes('blog')

  const breadcrumbItems = useMemo(() => {
    let breadcrumbData =  [
      { label: 'Garden of Dreams Foundation', url: '/' },
    ]
    if (!id) {
      return breadcrumbData
    }
  
    if (pid && menu.length > 0) {  // Ensure menu is available
      const parentItem = menu.find((item) => item?.uid === pid)
      if (parentItem) {
        breadcrumbData.push({ label: parentItem.title, url: `/${pid}` })
      }
    }
  
    breadcrumbData.push({ label: data?.title || '', url: '' })
  
    return breadcrumbData
  }, [id, pid, data, menu])

  useEffect(() => {
    if (!isBlogPage) {
      return
    }
    if (isBlogPage) {
      return navigate(`/blog`)
    }
  }, [isBlogPage])

  useEffect(() => {
    const myCarouselElement: Element | null = document.querySelector('#carouselExampleCaptions')
    if (myCarouselElement) {
      const carousel = new Carousel(myCarouselElement, {
        interval: 1000,
        touch: false,
        wrap: true,
      })
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const findTagValue = () => {
      const sliders = document.querySelectorAll('.carousel-blog-section')

      if (sliders.length) {
        sliders.forEach((slider) => {
          const id = slider.getAttribute('id')
          const tagValue = slider.getAttribute('tag')
          const title = slider.getAttribute('title')
          const description = slider.getAttribute('description')
          const showmore = slider.getAttribute('show_learn_more')
          const showNav = slider.getAttribute('show_nav')

          if (tagValue && id) {
            const getData = async (tagValue: any) => {
              let res = await getTags(tagValue)
              const content = res?.data?.article

              // Get the target element by its ID
              var targetElement = document.getElementById(id)
              // Inject HTML using innerHTML

              if (targetElement) {
                ReactDOM.render(
                  <CarouselOwl
                    data={content}
                    title={title}
                    showmore={showmore}
                    handleClick={hanldeCarouselCLick}
                    description={description}
                    showNav={showNav}
                  />,
                  targetElement,
                )
              }
            }

            getData(tagValue)
          } else {
            console.log(' Tag attribute not found on the element')
          }
        })
      } else {
        timeoutId = setTimeout(findTagValue, 100)
      }
    }

    findTagValue()

    // Cleanup: Clear the timeout if the component unmounts or the slider is found
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (!id || id === undefined) {
      setUid(landingId)
    } else {
      let uuid = id;
      if (sid) {
        uuid = `${sid}/${id}`;
      }
      setUid(uuid)
    }
  }, [id, sid, navigate])

  useEffect(() => {
    if (category?.length > 0) {
      setdata(category?.find((item) => item?.uid === uid))
    }

    const getData = async () => {
      setloader(true)
      try {
        if (category?.findIndex((item) => item?.uid === uid) === -1) {
          let result: any = await getArticleById(uid)

          if (!!result && result?.data && result?.error === false) {
            setcategory((prev: any) => [...prev, result?.data])
          }
          setloader(false)
        }

        setloader(false)
      } catch (error: any) {
        if (error?.response?.data?.error?.status === 403) {
          let result: any = await getArticleById(uid, true)
          if (!!result && !result.error && result?.data) {
            setcategory((prev: any) => [...prev, result?.data])
          }
        }
        console.error('Error fetching data:', error)
        setloader(false)
      } finally {
        setloader(false)
      }
    }

    getData()
  }, [uid, sid, category, setcategory, navigate])

  useEffect(() => {
    $('#carouselSlider').ready(function () {
      $('.owl-prev').html('<i class="fa fa-chevron-left"></i>')
      $('.owl-next').html('<i class="fa fa-chevron-right"></i>')
      $('.owl-carousel').owlCarousel({
        responsiveClass: true,
        margin: 10,
        dotsEach: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 3,
            loop: true,
          },
          1000: {
            items: 3,
            loop: true,
            nav: true,
          },
        },
      })
    })
    $('.superblockslider__track > p').replaceWith(function () {
      return $(this).contents()
    })
  }, [uid, category, setcategory])

  useEffect(() => {
    const handleClick = (event: any) => {
      if (event.target.matches('.has-luminous-vivid-amber-background-color')) {
        event.preventDefault();

        const targetLink = event.target.getAttribute('href');
        trackCarouselClick(
          'request_tickets_cta',
          window.location.href,
          'cta',
          'block',
          '',
          'click_content'
        );

        setTimeout(() => {
          window.location.href = targetLink;
        }, 1000);
      }
    };

    document.addEventListener('click', handleClick);    
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (event.target.matches('.flockler-carousel_v2-item__context__source')) {
        event.preventDefault();

        const targetLink = event.target.getAttribute('href');
        trackCarouselClick(
          'social_medial_click',
          window.location.href,
          'cta',
          'social_block',
          event.pointerId.toString(),
          'click_content',
          'external'
        );

        setTimeout(() => {
          window.location.href = targetLink;
        }, 1000);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const getComponent = () => {
    const components: React.ReactNode[] = []

    const item = category?.find((item) => item?.uid === id)
    const title = item?.title

    // Define a mapping of titles to components
    const titleToComponent: Record<string, React.ReactNode> = {
      Blog: <Blog />,
    }

    // Check if the title exists in the mapping and push the component if found
    if (title && titleToComponent[title]) {
      components.push(titleToComponent[title])
    }

    return components
  }

  useEffect(() => {
    /**
     * Appends the Breadcrumb component to the DOM
     */
  if (!data || !menu.length) return

  const divElementCoverBlock = document.querySelector('.wp-block-cover')
  const divElementBreadcrumbContainer = document.querySelector('.breadcrumb-container')
  if (divElementCoverBlock && !divElementBreadcrumbContainer) {
    const breadcrumbContainer = document.createElement('div')
    ReactDOM.render(<Breadcrumb items={breadcrumbItems} />, breadcrumbContainer)
    divElementCoverBlock.insertAdjacentElement('afterend', breadcrumbContainer)
  }
}, [data, breadcrumbItems, menu])

  if (loader) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }

  const isEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object

  if (data === undefined || isEmpty(data)) {
    return (
        <PageNotFound />  
    )
  }
  const wrapperClass = id ? `${sid} ${id}` : ' landing'
  return (
    <div className={`category ${wrapperClass}`}>
      {data?.hero_banner_slider && data?.hero_banner_slider.length && (
        <HeroBannerSlider data={data?.hero_banner_slider} />
      )}

      <Helmet>
        <link rel='canonical' href={data?.canonical_url}></link>
        <title>{data.meta_title}</title>
        <meta name='description' content={data.meta_description} />
        <meta name='keywords' content={data.meta_key} />
      </Helmet>

      {data?.content && <div dangerouslySetInnerHTML={getHtml(data?.content)} />}
      {getComponent()}
    </div>
  )
}

export default Category
