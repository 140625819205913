import { FC } from "react";
import { ReactComponent as RightIcon } from "./assets/right-icon.svg";
import { trackCarouselClick } from "../../helper/utils";

interface PrevArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const PrevArrow: FC<PrevArrowProps> = ({ className, style, onClick }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    trackCarouselClick("click_carousel:left", window.location.href, "carousel", "carousel");
    onClick?.();
  };
  return (
    <div
      className={className}
      onClick={handleClick}
    >
      <RightIcon className="svg-nav-icon"/>
    </div>
  );
};

export default PrevArrow;